import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Mondo Computazionale' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
      Mondo Computazionale è un progetto che ha come scopo quello di avvicinare i giovani e gli adulti al mondo dell’informatica, rendendoli più consapevoli delle tecnologie che si utilizzano tutti i giorni. Diffondere, aiutare a capire il coding e l’arte della programmazione in modo che chiunque possa avvicinarsi a questo mondo.
      </Text>
    </Card>
  </Section>
)

export default Commitment
